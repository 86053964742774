.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

h1 {
  font-size: xxx-large;
}
@media only screen and (max-width: 900px), only screen and (max-height: 900px) {
  h1 {
    font-size: 5vmin;
  }
}

p {
  font-size: medium;
}
@media only screen and (max-width: 900px), only screen and (max-height: 900px) {
  p {
    font-size: 1.6vmin;
  }
}
@media only screen and (max-width: 600px), only screen and (max-height: 600px) {
  p {
    font-size: 2vmin;
  }
}

.grid-container {
  display: grid;
  column-gap: 20px;
  row-gap: 10px;
}

.basic-long-text-div {
  padding: 0 8px;
  text-align: left;
  max-width: 512px;
}
@media only screen and (max-width: 900px), only screen and (max-height: 900px) {
  .basic-long-text-div {
    font-size: 1.6vmin;
  }
}
@media only screen and (max-width: 600px), only screen and (max-height: 600px) {
  .basic-long-text-div {
    font-size: 2.8vmin;
    max-width: none;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
}

button {
  background-color: #454c5a;
  color: white;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  overflow: hidden;
  overflow-wrap: anywhere;
}
button:active {
  background-color: #8992a4;
}
button:hover {
  background-color: #8992a4;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
